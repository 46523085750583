.qr_code_android {
    background-size: cover;
    width: 250px;
    height: 250px;
    background-image: url("../../assets/android.png");
}

.qr_code_iphone {
    background-size: cover;
    width: 250px;
    height: 250px;
    background-image: url("../../assets/Iphone.png");
}

.qr_block {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.title_OS {
    font-weight: bold;
    font-size: 25px;
}

@media screen and (max-width: 1440px) {
    .qr_code {
        width: 200px;
        height: 200px;
    }
}

@media screen and (max-width: 768px) {
    .qr_code {
        width: 170px;
        height: 170px;
    }
}

@media screen and (max-width: 426px) {
    .qr_code {
        width: 150px;
        height: 150px;
    }

    .title_OS {
        font-size: 20px;
    }
}

@media screen and (max-width: 320px) {
    .qr_code {
        width: 130px;
        height: 130px;
    }
}