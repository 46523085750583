.card {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    background: white;
    min-width: 300px;
    min-height: 200px;
    border-radius: 20px;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.title_card {
    font-size: 20px;
    font-weight: bold;
}

.button_pdf_format {
    display: inline-block;
    width: 50px;
    padding: 20px;
    font-size: 15px;
    background: none;
    border: 3px #113932 solid;
    padding: 10px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    width: 200px;
}

@media screen and (max-width: 768px) {
    .title_card {
        font-size: 15px;
    }

    .button_pdf_format {
        font-size: 15px;
    }

    .card {
        margin-top: 30px;
    }
}

@media screen and (max-width: 426px) {
    .card {
        margin-top: 30px;
        min-width: 250px;
        min-height: 150px;
    }
}