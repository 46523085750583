@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amiri:wght@700&family=Merriweather&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: Montserrat;
    color: black;
}

a {
    text-decoration: none;
}

#root {
    width: 100%;
    text-align: center;
}

.container {
    max-width: 1400px;
    margin: 0 auto;
    min-height: 100%;
}

.our_app {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 110px;
    width: 100%;
    background: white;
}

.our_app_title {
    font-size: 25px;
}

.green_text {
    font-weight: bold;
    color: #113932;
}

.phone {
    background-size: cover;
    width: 400px;
    height: 740px;
    background-image: url("./assets/phone.png");
}

.phone_section {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    min-height: 700px;
}

.our_app_subtitle {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 24px;
    max-width: 700px;
}

.title_our_app {
    margin-left: 50px;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 30px;
}

.good_luck {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 30px;
}

.qr_codes {
    display: flex;
    justify-content: space-between;
}

.phone_container {
    padding: 100px;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.statistic_section {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 140px;
    width: 100%;
    background: #056226;
}

.statistic_title {
    font-size: 40px;
    color: white;
    font-weight: bold;
}

.white_text {
    color: white;
}

.background {
    background-size: cover;
    max-width: 100%;
    background-image: url("./assets/background.png");
}

.logo {
    background-size: contain;
    width: 200px;
    height: 200px;
    background-image: url("./assets/logo.png");
}

.main_title {
    font-size: 40px;
    font-weight: bold;
    max-width: 910px;
    line-height: 50px;
}

.title_about {
    font-size: 30px;
    font-weight: bold;
}

.paragraph {
    padding-top: 120px;
    padding-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.underline {
    border-bottom: 3px black solid;
    font-family: Amiri;
}

.subtitle_about {
    font-size: 22px;
    max-width: 700px;
}

.button_pdf {
    border-radius: 20px;
    max-width: 400px;
    padding: 20px;
    font-size: 20px;
    background: none;
    border: 3px black solid;
}

.about_us {
    margin: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.cooperation {
    font-size: 35px;
    line-height: 20px;
    font-weight: bold;
    padding: 0px;
}

.block_cooperation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60px;
    margin-top: 40px;
    margin-bottom: 50px;
}

.cards {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.wrap {
    width: 100%;
    min-height: 100%;
}

.size {
    font-size: 22px;
}

.support {
    margin-top: 60px;
    margin-bottom: 50px;
}

.background_section {
    background-size: cover;
    max-width: 100%;
    background-image: url("./assets/background_section.png");
}

@media screen and (max-width: 1440px) {
    .our_app_subtitle {
        font-size: 24px;
    }

    .good_luck {
        font-size: 35px;
    }

    .cards {
        max-width: 1000px;
    }

    .phone_container {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 20px;
    }

    .container {
        max-width: 1200px;
    }

    .phone {
        width: 300px;
        height: 580px;
    }

    .qr_codes {
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
}

@media screen and (max-width: 1265px) {
    .paragraph {
        padding: 110px;
    }

    .our_app {
        top: -4px;
        position: relative;
        z-index: auto;
    }
}

@media screen and (max-width: 1024px) {
    .phone_container {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        padding: 30px;
    }

    .container {
        max-width: 900px;
    }

    .title_our_app {
        width: 60%;
        margin-left: 0px;
    }

    .our_app_subtitle {
        align-items: center;
    }

    .good_luck {
        align-items: center;
    }

    .statistic_title {
        font-size: 30px;
    }
}
@media screen and (max-width: 768px) {
    .main_title {
        font-size: 30px;
        line-height: 34px;
    }

    .title_about {
        font-size: 25px;
    }

    .subtitle_about {
        font-size: 20px;
    }

    .our_app_title {
        font-size: 22px;
    }

    .container {
        max-width: 650px;
    }

    .paragraph {
        padding: 60px;
    }

    .phone {
        width: 300px;
        height: 580px;
    }

    .our_app_subtitle {
        font-size: 20px;
    }

    .good_luck {
        font-size: 25px;
    }

    .statistic_title {
        font-size: 25px;
    }

    .cooperation {
        font-size: 25px;
    }

    .size {
        font-size: 15px;
    }

    .cards {
        justify-content: center;
        flex-wrap: wrap;
        max-width: 900px;
    }

    .qr_codes {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 426px) {
    .paragraph {
        padding: 20px 0px;
    }

    .phone_container {
        padding: 0px;
    }

    .main_title {
        font-size: 25px;
        line-height: 25px;
    }

    .qr_codes {
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .container {
        max-width: 320px;
    }

    .title_our_app {
        width: 100%;
        padding-bottom: 200px;
        height: 670px;
    }

    .statistic_title {
        padding: 10px;
    }

    .our_app {
        margin-bottom: 30px;
    }

    .our_app_subtitle {
        font-size: 16px;
    }

    .main_title {
        font-size: 20px;
    }

    .underline {
        border-bottom: 2px solid black;
    }

    .title_about {
        font-size: 15px;
    }

    .subtitle_about {
        font-size: 15px;
    }

    .button_pdf {
        font-size: 15px;
    }

    .our_app_title {
        font-size: 15px;
    }

    .phone {
        width: 200px;
        height: 390px;
    }

    .our_app_subtitle {
        font-size: 15px;
    }

    .good_luck {
        font-size: 25px;
    }

    .statistic_title {
        font-size: 20px;
    }

    .cooperation {
        font-size: 22px;
    }

    .about_us {
        margin: 10px;
    }

    .cards {
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .logo {
        width: 150px;
        height: 150px;
    }
}

@media screen and (max-width: 320px) {
    .phone {
        width: 180px;
        height: 350px;
    }

    .our_app_subtitle {
        font-size: 12px;
    }

    .good_luck {
        font-size: 15px;
    }

    .title_our_app {
        padding-bottom: 240px;
        height: 550px;
    }

    .statistic_title {
        font-size: 15px;
    }

    .cooperation {
        font-size: 18px;
    }

    .size {
        font-size: 12px;
    }

    .container {
        max-width: 220px;
    }
}